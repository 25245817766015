import type { CoreFetch } from '~/types/globalDeclarations/CoreFetch'

export type NudgeName =
  | 'bio'
  | 'liked_subgenres'
  | 'hated_subgenres'
  | 'service_details'
  | 'similar_artists'
  | 'lyrics_lang_countries'
  | 'track_age'
  | 'spotify_playlists'
  | 'social_media'

export enum NudgeType {
  fill = 'fill_section_nudges',
  update = 'up_to_date_nudges',
}

export type ProfileCompletionData = {
  fill_section_nudges: {
    score: number // % of profile completion
    nudges: NudgeName[] // names of nudges corresponding to profile sections yet to be filled
  }
  ignored_nudges: NudgeName[] // list of previously ignored nudges
  up_to_date_nudges: NudgeName[] // names of nudges corresponding to profile sections that could be updated
}

export function provideFetchCuratorProfileCompletion(coreFetch: CoreFetch) {
  return function (influencerId: number): Promise<ProfileCompletionData> {
    return coreFetch.$get(`influencer/influencer/${influencerId}`)
  }
}

type IgnoredNudgePatchPayload = {
  ignored_nudges: NudgeName[]
  last_profile_nudges_display?: string
}

export function providePatchCuratorIgnoredNudges(coreFetch: CoreFetch) {
  return function (
    influencerId: number,
    ignoredNudges: NudgeName[],
    wasLastNudgeIgnored = false,
  ): Promise<any> {
    const payload: IgnoredNudgePatchPayload = {
      ignored_nudges: ignoredNudges,
    }
    if (wasLastNudgeIgnored)
      payload.last_profile_nudges_display = new Date().toISOString()

    return coreFetch.$patch(`influencer/influencer/${influencerId}/`, payload)
  }
}
